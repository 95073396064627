import { useState } from "react";
import Pagination from "../Components/Pagination";

export const usePaginate = ({start = 1}) => {
    const [currentPage, setCurrentPage] = useState(start);
    const [totalPages, setTotalPages] = useState(0);

    const PaginateContainer = () => <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />

    return {currentPage, setTotalPages, PaginateContainer}
}