export const login = `${process.env.REACT_APP_BACKEND_URL}/auth/login`;
export const register = `${process.env.REACT_APP_BACKEND_URL}/auth/register`;
export const session = `${process.env.REACT_APP_BACKEND_URL}/users/user`;
export const verifyEmail = `${process.env.REACT_APP_BACKEND_URL}/auth/verify-email`;
export const sendEmailOTP = `${process.env.REACT_APP_BACKEND_URL}/auth/email-otp`;
export const sendSMSOTP = `${process.env.REACT_APP_BACKEND_URL}/auth/sms-otp`;
export const verifyPhone = `${process.env.REACT_APP_BACKEND_URL}/auth/verify-phone`;

export const users = `${process.env.REACT_APP_BACKEND_URL}/users`;
export const userUpdateURL = (id) =>
  `${process.env.REACT_APP_BACKEND_URL}/users/${id}/account`;
export const updateUserPicture = `${process.env.REACT_APP_BACKEND_URL}/users/update/photo`;

export const categories = `${process.env.REACT_APP_BACKEND_URL}/categories`;

export const shops = `${process.env.REACT_APP_BACKEND_URL}/shops`;
export const shopProducts = (shop_id) =>
  `${process.env.REACT_APP_BACKEND_URL}/products/${shop_id}/shop`;
export const searchShops = `${process.env.REACT_APP_BACKEND_URL}/shops/search`;

export const products = `${process.env.REACT_APP_BACKEND_URL}/products`;
export const topDiscountProducts = `${process.env.REACT_APP_BACKEND_URL}/products/top-discount`;
export const bestSellerProducts = `${process.env.REACT_APP_BACKEND_URL}/products/best-seller`;
export const featuredProducts = `${process.env.REACT_APP_BACKEND_URL}/products/featured`;
export const searchProducts = `${process.env.REACT_APP_BACKEND_URL}/products/search`;
export const exploreProducts = `${process.env.REACT_APP_BACKEND_URL}/products/explore`;

export const variants = `${process.env.REACT_APP_BACKEND_URL}/variants`;

export const orders = `${process.env.REACT_APP_BACKEND_URL}/orders`;

export const chats = `${process.env.REACT_APP_BACKEND_URL}/chats`;

export const usersSocketUrl = `${process.env.REACT_APP_BACKEND_URL}/malonda265/users/`;
export const chatSocketUrl = `${process.env.REACT_APP_BACKEND_URL}/malonda265/chat/`;
export const ordersSocketUrl = `${process.env.REACT_APP_BACKEND_URL}/malonda265/orders/`;

export const subscriptions = `${process.env.REACT_APP_BACKEND_URL}/subscriptions`;
export const subscriptionTypes = `${process.env.REACT_APP_BACKEND_URL}/subscriptions/types`;

export const orderStats = `${process.env.REACT_APP_BACKEND_URL}/stats/orders`;
export const stats = `${process.env.REACT_APP_BACKEND_URL}/stats`;

export const wishlist = `${process.env.REACT_APP_BACKEND_URL}/wishlists`;

export const payments = `${process.env.REACT_APP_BACKEND_URL}/payments`;
