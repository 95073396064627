import { useEffect, useState } from "react";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
  FaAngleLeft,
} from "react-icons/fa";

export default ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];
  if (totalPages > 10) {
    for (let i = 1; i <= totalPages; i++) {
      if (i < 4 || i > totalPages - 3) {
        pageNumbers.push(i);
        continue;
      }

      if (i > currentPage - 3 && i < currentPage + 3) {
        pageNumbers.push(i);
        continue;
      }

      if (i == currentPage - 4 || i == currentPage + 4) {
        pageNumbers.push("...");
        continue;
      }
    }
  } else {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
  }

  return (
    <nav className={totalPages===1 ? "hidden" : "flex flex-col items-center gap-2"}>
      <ul className="flex items-center -space-x-px h-8 text-sm">
        <li
          className={`${1 === currentPage ? "disabled" : ""} lg:hidden block`}
        >
          <button
            onClick={() => onPageChange(1)}
            disabled={currentPage <= 1}
            className={`${
              currentPage <= 1
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleDoubleLeft />
          </button>
        </li>
        <li
          className={`${1 === currentPage ? "disabled" : ""} lg:hidden block`}
        >
          <button
            onClick={() => onPageChange((page) => page - 1)}
            disabled={currentPage <= 1}
            className={`${
              currentPage <= 1
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleLeft />
          </button>
        </li>

        <li
          className={`${
            currentPage >= totalPages ? "disabled" : ""
          } lg:hidden block`}
        >
          <button
            onClick={() => onPageChange((page) => page + 1)}
            disabled={currentPage >= totalPages}
            className={`${
              currentPage >= totalPages
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleRight />
          </button>
        </li>
        <li
          className={`${
            currentPage >= totalPages ? "disabled" : ""
          } lg:hidden block`}
        >
          <button
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage >= totalPages}
            className={`${
              currentPage >= totalPages
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleDoubleRight />
          </button>
        </li>
      </ul>

      <ul className="flex items-center -space-x-px h-8 text-sm">
        <li
          className={`${1 === currentPage ? "disabled" : ""} hidden lg:block`}
        >
          <button
            onClick={() => onPageChange(1)}
            disabled={currentPage <= 1}
            className={`${
              currentPage <= 1
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleDoubleLeft />
          </button>
        </li>
        <li
          className={`${1 === currentPage ? "disabled" : ""} hidden lg:block`}
        >
          <button
            onClick={() => onPageChange((page) => page - 1)}
            disabled={currentPage <= 1}
            className={`${
              currentPage <= 1
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleLeft />
          </button>
        </li>

        {pageNumbers.map((page) => {
          if (page == "...")
            return (
              <li>
                <div className="z-10 flex items-center justify-center px-3 h-8 leading-tight text-gray-500">
                  ...
                </div>
              </li>
            );
          return (
            <li
              key={page}
              className={`${page === currentPage ? "disabled" : ""}`}
            >
              <button
                onClick={() => onPageChange(page)}
                className={`${
                  page === currentPage
                    ? "disabled text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                    : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                } flex items-center justify-center px-3 h-8 leading-tight`}
              >
                {page}
              </button>
            </li>
          );
        })}

        <li
          className={`${
            currentPage >= totalPages ? "disabled" : ""
          } hidden lg:block`}
        >
          <button
            onClick={() => onPageChange((page) => page + 1)}
            disabled={currentPage >= totalPages}
            className={`${
              currentPage >= totalPages
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleRight />
          </button>
        </li>
        <li
          className={`${
            currentPage >= totalPages ? "disabled" : ""
          } hidden lg:block`}
        >
          <button
            onClick={() => onPageChange(totalPages)}
            disabled={currentPage >= totalPages}
            className={`${
              currentPage >= totalPages
                ? "disabled text-blue-600 border border-blue-300 bg-blue-50 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                : "z-10 text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            } flex items-center justify-center px-3 h-8 leading-tight`}
          >
            <FaAngleDoubleRight />
          </button>
        </li>
      </ul>
    </nav>
  );
};
