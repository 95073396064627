import { Link } from "react-router-dom";
import MainLayout from "../Layouts/MainLayout";

const NotFound = () => {
  return (
    <MainLayout>
      <div className="text-center flex flex-col items-center my-28 space-y-6">
        <div className="w-[350px] sm:w-[500px]">
          <img src="/img/not-found.svg" className="object-cover" />
        </div>
        <p className=" text-md sm:text-2xl italic">
          Oops! The page you are looking for might be under construction, does
          not exist or requires you to login.
        </p>
        <p className="text-2xl">
          Let's go back to the{" "}
          <Link className="text-blue-500 underline underline-offset-2" to="/">
            home page
          </Link>
          .
        </p>
      </div>
    </MainLayout>
  );
};

export default NotFound;
