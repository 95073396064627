import Container from "../Components/Container";
import Checkbox from "../Components/Checkbox";
import TextInput from "../Components/TextInput";
import PrimaryButton from "../Components/PrimaryButton";
import DangerButton from "../Components/DangerButton";
import ProductCard from "./ProductCard";
import BuyNowPopup from "./BuyNowPopup";
import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { usePaginate } from "../Hooks/usePaginate";

export default ({
  categories,
  products,
  isProcessing = false,
  Pagination = <></>,
}) => {
  const [activeProduct, setActiveProduct] = useState(null);
  const [showBuyNow, setShowBuyNow] = useState(false);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [priceRange, setPriceRange] = useState(null);
  const [isShowFilter, setIsShowFilter] = useState(false);

  const openModal = (product) => {
    setActiveProduct(product);
    setShowBuyNow(true);
  };

  const onChangeCategory = (checked, category) => {
    if (checked) {
      if (selectedCategories.includes(category.id)) return;
      setSelectedCategories((prevState) => [...prevState, category.id]);
    } else {
      const newCats = selectedCategories.filter((cat) => cat !== category.id);
      setSelectedCategories(newCats);
    }
  };

  const applyPrice = () => {
    if (!minPrice && !maxPrice) {
      toast.error("Enter a valid number in the input fields!");
      return;
    }
    if (!!maxPrice)
      if (minPrice > maxPrice)
        toast.error("Max Price should be greater than minimum price!");

    setPriceRange([
      !!minPrice ? minPrice : 0,
      !!maxPrice ? maxPrice : 999999999999999,
    ]);
  };

  const filteredProducts = useMemo(
    () =>
      products?.filter((product) => {
        if (selectedCategories.length > 0) {
          if (!selectedCategories.includes(product.Category.id)) {
            return false;
          }
        }

        if (priceRange?.length > 0) {
          if (product.price < priceRange[0] || product.price > priceRange[1]) {
            return false;
          }
        }

        return true;
      }),
    [selectedCategories, priceRange, products, categories, isProcessing]
  );

  const resetFilters = () => {
    const checkBoxes = document.querySelectorAll(".category_checks");
    checkBoxes.forEach((checkbox) => {
      checkbox.checked = false;
    });

    setSelectedCategories([]);
    setMinPrice("");
    setMaxPrice("");
    setPriceRange(null);
  };

  const filters = (
    <>
      <div className="flex justify-between items-center mb-4 w-full">
        <div className="text-lg font-bold hidden md:inline-block">Filters</div>
        <div></div>
        <DangerButton onClick={resetFilters}>Reset</DangerButton>
      </div>
      <div className="">Categories</div>
      <div className="ml-4">
        {!!categories &&
          categories.map((category) => (
            <div key={category.id} className="block mt-2">
              <label className="flex items-center">
                <Checkbox
                  className="category_checks"
                  onChange={(e) => onChangeCategory(e.target.checked, category)}
                />
                <span className="ml-2 text-sm">{category.name}</span>
              </label>
            </div>
          ))}
      </div>

      <div className=" mt-4">Price Range</div>
      <div className="space-y-2">
        <div className="flex items-stretch gap-2">
          <div>
            <TextInput
              type="number"
              className="w-full"
              placeholder="Min."
              value={minPrice}
              min={0}
              onChange={(e) => setMinPrice(e.target.value)}
            />
          </div>
          <div className="text-lg font-bold self-center">-</div>
          <div>
            <TextInput
              type="number"
              className="w-full"
              placeholder="Max."
              value={maxPrice}
              min={!!minPrice ? minPrice + 1 : 0}
              onChange={(e) => setMaxPrice(e.target.value)}
            />
          </div>
        </div>
        <div className="space-x-2">
          <PrimaryButton onClick={applyPrice}>Apply</PrimaryButton>
        </div>
      </div>
    </>
  );

  return (
    <Container
      size="w-full"
      ypadding="py-2"
      xpadding="md:px-2"
      className="md:grid grid-cols-5 gap-2"
      padding="p-2"
    >
      {showBuyNow && (
        <BuyNowPopup
          show={showBuyNow}
          close={() => setShowBuyNow((s) => !s)}
          product={activeProduct}
        />
      )}
      {/* Filters Section */}
      <div className="border-0 border-e p-2 hidden md:inline-block">
        {filters}
      </div>

      <div className="md:hidden block">
        <h2 id="accordion-flush-heading-1">
          <button
            type="button"
            onClick={() => setIsShowFilter((s) => !s)}
            className={`flex items-center justify-between w-full py-5 font-medium rtl:text-right border-b border-gray-200 dark:border-gray-700 gap-3 
            ${
              isShowFilter
                ? "text-gray-900 dark:text-white "
                : "text-gray-500 dark:text-gray-400"
            }`}
          >
            <span>Filters</span>
            {isShowFilter ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
        </h2>
        <div className={`${!isShowFilter ? "hidden" : ""}`}>
          <div className="py-5 border-b border-gray-200 dark:border-gray-700">
            {filters}
          </div>
        </div>
      </div>

      <div className="md:col-span-4">
        <div className="sm:grid md:grid-cols-2 lg:grid-cols-3 gap-2">
          {isProcessing ? (
            <div className="flex justify-center items-center h-96 col-span-4">
              Processing...
            </div>
          ) : !!filteredProducts && filteredProducts?.length > 0 ? (
            filteredProducts?.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                openModal={openModal}
              />
            ))
          ) : (
            <div className="flex justify-center items-center h-96 col-span-4">
              No items to show!
            </div>
          )}
        </div>
      </div>
      <div className="md:col-span-5 flex justify-center md:justify-end">
        <Pagination />
      </div>
    </Container>
  );
};
