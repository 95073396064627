import { useEffect, useState } from "react";
import Container from "../../../Components/Container";
import {
  categories as categoryURL,
  shopProducts,
} from "../../../Data/apiRoutes";
import ProductsPanel from "../../../Widgets/ProductsPanel";
import { usePaginate } from "../../../Hooks/usePaginate";

export default ({ shop }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const {
    currentPage,
    setTotalPages,
    PaginateContainer,
  } = usePaginate({ start: 1 });

  const fetchCategories = async () => {
    try {
      setIsProcessing(true);
      const response = await fetch(categoryURL);
      const data = await response.json();
      setCategories(data.categories);
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  const fetchProducts = async (pageNumber = 1) => {
    try {
      setIsProcessing(true);
      const response = await fetch(`${shopProducts(`${shop?.id}`)}?page=${pageNumber}`);
      const data = await response.json();
      console.log(data)
      setProducts(data.products);
      // setTotalPages(data.pages);
      setIsProcessing(false);
    } catch (error) {
      console.log(error);
      setIsProcessing(false);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchProducts();
  }, [shop]);

  // useEffect(() => {
  //   fetchProducts(currentPage);
  // }, [currentPage]);

  return (
    <Container size="w-full" ypadding="pt-5" xpadding="" transparent padding="">
      <div className="mt-10 text-center text-lg font-black mb-3">
        {shop?.name}
      </div>
      <div>
        {<ProductsPanel
          categories={categories}
          products={products}
          isProcessing={isProcessing}
          Pagination={PaginateContainer}
        />}
      </div>
    </Container>
  );
};
