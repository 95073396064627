import { IoMdStar, IoMdStarHalf, IoMdStarOutline } from "react-icons/io";
import { numberFormat } from "../Functions/functions";
import { Link } from "react-router-dom";

export default ({ product }) => (
  <div className="flex-shrink-0">
    <div className="flex justify-center">
      <Link
        to={`/products/${product?.id}`}
        className="h-[180px] w-[180px] p-[5px] bg-white bg-opacity-20 hover:bg-opacity-50 transition-all rounded cursor-pointer"
      >
        <img
          src={
            product?.picture
              ? `${process.env.REACT_APP_BACKEND_URL}/${product?.picture
                  ?.replace('"', "")
                  .replace('"', "")}`
              : "/img/image_icon1.png"
          }
          className="object-cover h-[170px] w-[170px]"
        />
      </Link>
    </div>
    <div className="text-center">
      <Link
        to={`/products/${product?.id}`}
        className="mt-2 font-bold cursor-pointer line-clamp-2"
      >
        {product?.name}
      </Link>
      <div className="text-sm mt-2">
        K {numberFormat(product?.price - product?.discount)}
      </div>
      {product?.discount > 0 && (
        <div className="text-xs line-through text-gray-500">
          K {numberFormat(product?.price)}
        </div>
      )}
      <div className="flex justify-center text-xs text-yellow-500">
        <IoMdStar />
        <IoMdStar />
        <IoMdStar />
        <IoMdStarHalf />
        <IoMdStarOutline />
      </div>
    </div>
  </div>
);
