import ToWishlistButton from "../Components/ToWishlistButton";
import BuyNowButton from "../Components/BuyNowButton";
import { Link } from "react-router-dom";
import InquiryButton from "../Components/InquiryButton";
import { useSelector } from "react-redux";

export default function ProductCard({ product, openModal }) {
  const user = useSelector((state) => state.user);

  return (
    <div className="my-2 sm:my-0 shadow dark:bg-gray-900 rounded p-2 sm:p-3">
      <div className="flex gap-3">
        <div
          className="relative sm:self-center"
          style={{
            width: "130px",
            minWidth: "130px",
            height: "130px",
            minHeight: "130px",
          }}
        >
          {new Date(product?.createdAt).getTime() >=
            Date.now() - 7 * 24 * 60 * 60 * 1000 && (
            <div className="absolute z-40 -mt-1 -ms-1">
              <div className="ease flex text-xs px-1 items-center justify-center rounded-full bg-green-700 transition-all duration-200">
                New
              </div>
            </div>
          )}

          {((product.discount / product.price) * 100).toFixed(2) > 0 && (
            <div className="absolute z-40 top-0 right-0 -mt-1 -me-1">
              <div className="ease flex text-xs px-1 items-center justify-center rounded-full bg-red-700 transition-all duration-200">
                -
                {((product.discount / product.price) * 100)
                  .toFixed(2)
                  .toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                %
              </div>
            </div>
          )}

          <div className="absolute z-10 bottom-0 w-full bg-blue-50 text-blue-950 dark:bg-blue-950 dark:text-blue-50 text-center opacity-90 text-xs">
            MWK{" "}
            {(product?.price - product?.discount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>

          <Link to={`/products/${product.id}`}>
            <img
              src={
                product?.picture
                  ? `${process.env.REACT_APP_BACKEND_URL}/${product?.picture
                      ?.replace('"', "")
                      .replace('"', "")}`
                  : "/img/shop-icon.png"
              }
              alt="product-1"
              className="object-cover w-[130px] h-[130px]"
            />
          </Link>
        </div>
        <div className="flex flex-col">
          <div className="pe-1">
            <Link
              to={`/products/${product?.id}`}
              className="font-bold hover:underline underline-offset-4 line-clamp-1"
            >
              {product?.name}
            </Link>
            <div className="text-sm text-gray-700 dark:text-gray-400 text-justify line-clamp-2">
              {product?.description}
            </div>
          </div>
          <div className="hidden md:flex items-stretch gap-2 md:gap-3 mt-auto">
            {user ? (
              <>
                <BuyNowButton
                  hideText={true}
                  onClick={() => openModal(product)}
                />
                <ToWishlistButton
                  hideText={true}
                  product={product}
                  user={user}
                />
                <InquiryButton
                  hideText={true}
                  user={product.User}
                  product={product}
                />
              </>
            ) : (
              <BuyNowButton onClick={() => openModal(product)} />
            )}
          </div>
        </div>
      </div>
      <div className="flex md:hidden items-stretch justify-around gap-2 md:gap-3 mt-2">
        {user ? (
          <>
            <BuyNowButton hideText={true} onClick={() => openModal(product)} />
            <ToWishlistButton hideText={true} product={product} user={user} />
            <InquiryButton
              hideText={true}
              user={product.User}
              product={product}
            />
          </>
        ) : (
          <BuyNowButton onClick={() => openModal(product)} />
        )}
      </div>
    </div>
  );
}
