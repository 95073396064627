import { useSelector } from "react-redux";
import MainLayout from "../../Layouts/MainLayout";
import SellerProducts from "./SellerProducts";
import CustomerProducts from "./CustomerProducts";
import { useEffect, useState } from "react";
import {
  products as productUrl,
  categories as categoriesURL,
} from "../../Data/apiRoutes";

export default function Products() {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  
  const fetchProducts = async (pageNumber = 1) => {
    try {
      const res = await (user?.role === "seller"
        ? fetch(`${productUrl}/mine`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user_id: user.id }),
          })
        : fetch(`${productUrl}`));
      const data = await res.json();
      setProducts(data.products);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategories = async () => {
    try {
      const res = await fetch(categoriesURL);
      const data = await res.json();
      setCategories(data.categories);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts(1);
    fetchCategories();
  }, []);

  return (
    <MainLayout>
      {user?.role === "seller" ? (
        <SellerProducts products={products} categories={categories} user={user} token={token} refresh={fetchProducts} />
      ) : (
        <CustomerProducts categories={categories} user={user} token={token} />
      )}
    </MainLayout>
  );
}
